@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
.banner {
  background-color: #a33596;
  padding: 24px 0 120px;
}
.banner1 {
  background-color: #f7ecf5;
  padding: 24px 0 25px;
}
h1 {
  font-family: Poppins;
  font-size: 64px !important;
  font-weight: 600 !important;
  line-height: 66px !important;
  letter-spacing: 0.01em;
  text-align: left;
  color: white;
}
p {
  font-family: Poppins;
  font-size: 22px !important;
  font-weight: 500 !important;
  line-height: 30px !important;
  letter-spacing: 0em;
  text-align: left;
  color: #ffffff;
  padding-top: 1rem;
}
.image-background {
  background-image: url("./Vector.svg");
  background-size: contain;
  background-repeat: no-repeat;
  align-items: baseline;
  background-position: center;
}
h3 {
  font-family: Poppins;
  font-size: 48px !important;
  font-weight: 600 !important;
  line-height: 56px !important;
  letter-spacing: 0em;
  text-align: center;
  color: #a33596;
}
.features {
  background-color: #f7dcf4;
  padding-bottom: 6rem;
}
.adjustment {
  padding: 6rem 4rem 4rem 4rem;
}
.feature-item {
  background-color: #ffffff73;
  border-radius: 27px;
  margin-top: 1.5rem;
  height: 320px;
}
h5 {
  font-family: Poppins;
  font-size: 11px;
  font-weight: 400;
  line-height: 15px;
  letter-spacing: 0em;
  text-align: center;
}
.feature-style {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4rem 5rem 4rem 5rem;
}
.feature-position {
  text-align: center;
}
footer {
  background-color: #a33596;
  padding-top: 2rem;
}
.social-icons {
  list-style-type: none;
  display: inline-block;
  margin: 6px;
}
a {
  font-family: Poppins;
  font-size: 20px;
  font-weight: 600;
  line-height: 28px;
  letter-spacing: 0.01em;
  text-align: center;
  color: white !important;
  text-decoration: none !important;
}
.para-small {
  font-family: Poppins;
  font-size: 16px !important;
  font-weight: 500 !important;
  line-height: 26px !important;
  letter-spacing: 0em;
  text-align: left;
}
.footer-content {
  list-style-type: none;
  display: inline-block;
  margin: 1rem;
}
.copyright-position {
  align-items: center;
  display: flex;
  justify-content: center;
}
.new-color {
  background-color: #f7ecf5;
}
.privacyandterms {
  font-family: Poppins;
  font-size: 18px !important;
  font-weight: 400 !important;
  line-height: 32px !important;
  letter-spacing: 0.0015em;
  text-align: left;
  color: black;
}
.privacyterms {
  font-family: Poppins;
  font-size: 48px !important;
  font-weight: 600 !important;
  line-height: 56px !important;
  letter-spacing: 0.01em;
  text-align: center;
  color: black;
  padding-top: 3rem;
  padding-bottom: 1rem;
}
.termsandconstions-a {
  font-family: Poppins;
  font-size: 20px;
  font-weight: 600;
  line-height: 28px;
  letter-spacing: 0.01em;
  text-align: center;
  color: black !important;
  text-decoration: none !important;
}
.terms-para-small {
  font-family: Poppins;
  font-size: 16px !important;
  font-weight: 500 !important;
  line-height: 26px !important;
  letter-spacing: 0em;
  text-align: left;
  color: black;
}
h4 {
  font-family: Poppins;
}
a {
  color: #a33596 !important;
}
